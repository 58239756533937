import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SEO } from 'components';
import { graphql, Link } from 'gatsby';
import PrevArrow from 'images/shared/PrevArrow';

const OnboardingDesigners = () => {
  return (
    <>
      <SEO pageSpecificTitle="Onboarding for Designers" />
      <Wrapper>
        <InnerWrapper>
          <BackButton to="/admin/onboarding/">
            <PrevArrow width="1.688rem" />
          </BackButton>
          <h1>
            Onboarding <span>- Designers</span>
          </h1>
          <Container>
            <h2>Coming soon...</h2>
          </Container>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const BackButton = styled(Link)`
  align-items: center;
  border-radius: 0.125rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-top: -0.75em;
  margin-bottom: 2rem;
  transition: 150ms ease-in-out;
  width: 3rem;

  &:hover {
    background-color: #000;
  }

  &:not(:hover) {
    background-color: var(--ax-gold);
  }

  &:active {
    background-color: #c1beac;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 1em;
  }
  h3 {
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--ax-gold);
    margin-bottom: 0.5em;
    margin-top: 1em;
  }
  h4 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  a {
    text-decoration: underline;
    color: var(--ax-gold);
  }
`;

const InnerWrapper = styled.div`
  grid-column: 2 / 3;
  max-width: 62.5rem !important;
  width: 100%;

  @media screen and (min-width: 48rem) {
    margin-top: -4.825rem;
  }

  @media screen and (min-width: 68.75rem) {
    margin: -3.5rem 0 9.1rem;
  }
`;

const Wrapper = styled.section`
  padding-top: 7.1rem;
  position: relative;
  min-height: auto;

  h1 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 0.01em;
    line-height: 1.208em;
    text-transform: capitalize;
    margin-bottom: 0.5em;
    span {
      font-size: 0.5em;
      color: var(--ax-gold);
      font-weight: 300;
    }
  }

  p {
    font-size: 1.25rem;
    letter-spacing: 0.001em;
    line-height: 1.6em;
    margin-bottom: 1em;
  }

  @media screen and (min-width: 48rem) {
    padding-top: 11.9rem;
    min-height: auto;
    h1 {
      font-size: 6rem;
      font-weight: 900;
      line-height: 1.219em;
    }
  }
`;

export default OnboardingDesigners;
